<template>
  <div class="container-fluid">
    <div class="row">
      <div class="panel-body menu-without-padding">
        <div
            aria-label="..."
            class="btn-group btn-group-justified money"
            role="group"
        >
          <div
              class="btn-group"
              role="group"
          >
            <button
                :class="isActive === 'settled' ? 'active btn btn-default' : 'btn btn-default'"
                @click="showSettled=true; isActive = 'settled'"
            > {{
                $t('system.settled')
              }}
            </button>
          </div>
          <div
              class="btn-group"
              role="group"
          >
            <button
                :class="isActive === 'not_settled' ? 'active btn btn-default' : 'btn btn-default'"
                @click="showSettled=false; isActive = 'not_settled'"
            > {{
                $t('system.not_settled')
              }}
            </button>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button
            @click="showFormClicked('deposit')"
            class="btn btn-success"
        >{{ $t('system.deposit') }}
        </button>
        <button
            @click="showFormClicked('payment')"
            class="btn btn-success"
        >{{ $t('system.payment') }}
        </button>
      </div>
      <div class="form-group">
        <form
            v-on:submit.prevent
            v-show="showForm"
        >
          <h3 v-if="activity=='payment'">{{ $t('system.payment') }}</h3>
          <h3 v-else>{{ $t('system.deposit') }}</h3>
          <div class="row money">
            <div class="col-sm-2">
              <label>{{ $t('system.amount') }}</label>
              <money
                  class="form-control"
                  name="amount"
                  v-model.lazy="form.amount"
              ></money>
            </div>
            <div class="col-sm-2">
              <label>{{ $t('system.issue') }}</label>
              <select
                  class="form-control"
                  v-model="form.selectedIssue"
              >
                <option
                    disabled
                    value=""
                >{{ $t('system.please_select_one') }}
                </option>
                <option
                    :value="issue.id"
                    v-bind:key="issue.id"
                    v-for="issue in issues"
                >{{ issue.subject }}
                </option>
              </select>
            </div>
            <div class="col-sm-2">
              <label>{{ $t('system.status') }}</label>
              <select
                  class="form-control"
                  v-model="form.status"
              >
                <option
                    disabled
                    value=""
                >{{ $t('system.please_select_one') }}
                </option>
                <option value="true">{{ $t('system.settled') }}</option>
                <option value="false">{{ $t('system.not_settled') }}</option>
              </select>
            </div>
            <div class="col-sm-2">
              <label>{{ $t('system.description') }}</label>
              <input
                  class="form-control"
                  v-model="form.description"
              >
            </div>
            <div class="col-sm-3">
              <el-button
                  @click.prevent.stop="saveForm"
                  type="primary"
              >{{ $t('system.save') }}
              </el-button>
              <el-button
                  @click.prevent.stop="cancelFormClicked"
                  type="danger"
              >
                {{ $t('system.cancel') }}
              </el-button>

            </div>
          </div>
        </form>
      </div>
      <div class="panel-body bg-white">
        <div class="table-responsive">
          <table class="table table-bordered table-hover no-pointer">
            <thead>
            <tr>
              <th>{{ $t('system.amount') }}</th>
              <th>{{ $t('system.issue') }}</th>
              <th>{{ $t('system.settled') }}</th>
              <th>{{ $t('system.created_at') }}</th>
              <th>{{ $t('system.description') }}</th>
              <th>{{ $t('system.delete') }}</th>
            </tr>
            </thead>
            <tbody v-if="showSettled">
            <tr
                v-bind:key="item.id"
                v-for="item in settledData"
            >
              <td>{{ item.amount | cash }}</td>
              <td>{{ findIssueById(item.issue_id) }}</td>
              <td>
                <select @change="paymentStatusChanged(item.id, $event)">
                  <option
                      :selected="item.settled==true"
                      value="true"
                  >{{ $t('system.settled') }}
                  </option>
                  <option
                      :selected="item.settled==false"
                      value="false"
                  >{{ $t('system.not_settled') }}
                  </option>
                </select>
              </td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.description }}</td>
              <td><i
                  @click="removeMoneyRow(item.id)"
                  aria-hidden="true"
                  class="fa fa-trash"
              ></i></td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr
                v-bind:key="item.id"
                v-for="item in unsettledData"
            >
              <td>{{ item.amount | cash }}</td>
              <td>{{ findIssueById(item.issue_id) }}</td>
              <td>
                <select @change="paymentStatusChanged(item.id, $event)">
                  <option
                      :selected="item.settled==true"
                      value="true"
                  >{{ $t('system.settled') }}
                  </option>
                  <option
                      :selected="item.settled==false"
                      value="false"
                  >{{ $t('system.not_settled') }}
                  </option>
                </select>
              </td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.description }}</td>
              <td><i
                  @click="removeMoneyRow(item.id)"
                  aria-hidden="true"
                  class="fa fa-trash"
              ></i></td>
            </tr>
            </tbody>
            <tfoot v-if="showSettled">
            <tr>
              <td>{{ settledSum | cash }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </tfoot>
            <tfoot v-else>
            <tr>
              <td>{{ unsettledSum | cash }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Vue from 'vue';
import swal from 'sweetalert2';
import _ from 'lodash';
import notification from '../../notification/notify';

export default {
  name: 'MoneyIndex',
  data() {
    return {
      initialize: 'money',
      issues: [],
      showForm: false,
      model: [],
      selectedIssue: null,
      form: {
        amount: 0,
        status: false,
        selectedIssue: 0,
        description: '',
      },
      showSettled: true,
      activity: 'payment',
      isActive: 'settled',
    };
  },
  computed: {
    settledData: function () {
      return this.model.filter(function (item) {
        return item.settled === 1;
      });
    },
    unsettledData: function () {
      return this.model.filter(function (item) {
        return item.settled === 0;
      });
    },
    settledSum: function () {
      let sum = 0;
      for (let item of this.settledData) {
        sum += item.amount;
      }
      return sum;
    },
    unsettledSum: function () {
      let sum = 0;
      for (let item of this.unsettledData) {
        sum += item.amount;
      }
      return sum;
    },
  },
  beforeMount() {
    this.$store.commit('SET_ACTIVE', 'money');
    this.fetchData();
  },
  methods: {
    fetchData() {
      let that = this;
      axios.get(this.initialize)
          .then(function (response) {
            Vue.set(that.$data, 'model', response.data.model);
            Vue.set(that.$data, 'issues', response.data.issues);
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                that.$t(error.response.data.error),
                'error');
          });
    },
    showFormClicked(activity) {
      if (activity === 'deposit') {
        this.activity = 'deposit';
      } else {
        this.activity = 'payment';
      }
      this.showForm = true;
    },
    cancelFormClicked() {
      this.showForm = false;
      this.form = {
        amount: 0,
        status: false,
        selectedIssue: 0,
        description: '',
      };
    },
    saveForm() {
      let that = this;
      let amount = 0;
      if (!_.isNumber(this.form.amount)) {
        return false;
      }
      if (this.activity === 'payment') {
        amount = this.form.amount;
      } else {
        amount = -(Number(this.form.amount));
      }
      let issueId = null;
      if (this.form.selectedIssue) {
        issueId = this.form.selectedIssue;
      }
      let data = {
        amount: amount,
        status: this.form.status,
        issueId: issueId,
        description: this.form.description,
      };
      axios.post('money', data)
          .then(function (response) {
            that.fetchData();
            that.cancelFormClicked();
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                that.$t(error.response.data.error),
                'error');
          });
    },
    findIssueById(issueId) {
      let issue = this.issues.find(function (issue) {
        return issue.id === issueId;
      });
      if (issue) {
        return issue.subject;
      }
      return null;
    },
    paymentStatusChanged(moneyId, event) {
      let that = this;
      let data = {
        status: event.target.value,
      };
      axios.put('money/' + moneyId, data)
          .then(function (response) {
            that.fetchData();
            that.cancelFormClicked();
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                that.$t(error.response.data.error),
                'error');
          });
    },
    removeMoneyRow(moneyId) {
      let that = this;
      swal.fire({
        title: that.$t('system.if_you_sure'),
        text: that.$t('system.moneyRemovalDescription'),
        type: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: that.$t('system.yes'),
        cancelButtonText: that.$t('system.no'),
      }).then(function () {
        axios.delete('money/' + moneyId)
            .then(function (response) {
              that.fetchData();
            })
            .catch(function (error) {
              notification.notify(
                  that.$t('notify.error'),
                  that.$t(error.response.data.error),
                  'error');
            });
      }).catch(swal.noop);
    },
  },
};
</script>
